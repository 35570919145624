import React from "react";

export default function About() {
  return (
    <section className="section about-section">
      <h1 className="section-title">about us</h1>
      <p>
        Franc photography integrates branded photography with innovative and
        engaging web apps aligned with your event theme for maximum brand
        exposure and engagement which ensures your brand shines on the day of
        your event and beyond.
      </p>
    </section>
  );
}
